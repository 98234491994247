<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="マニフェスト複数予約" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- マニフェスト情報 -->
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">マニフェスト情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required fixed_width">
                <label>事業場</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <label v-if="generateStoreOpt.length === 1">
                    {{ generateStoreOpt[0].name }}
                  </label>
                  <div v-else>
                    <v-autocomplete ref="generate_store_autocomplete"
                                    :isValid="validate.generateStoreId.isValid"
                                    :errMsg="validate.generateStoreId.errMsg"
                                    @change="s => {onChange(s, 'generate_store_autocomplete')}" placeholder=""
                                    class="c-input md_l" v-bind:initItems="this.generateStoreOpt"
                                    :items="this.selectGenerateStoreList" :min-len="0" :wait="300"
                                    @update-items='updateGenerateStoreList'
                                    v-model="selectedGenerateStore"
                                    :component-item='selectTemplate' :auto-select-one-item="this.autoSelect"
                                    :get-label='getLabel'>
                    </v-autocomplete>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label fixed_width">
                <label>引き渡し日</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputDate
                    :value.sync="formValue.collectionDate"
                    :placeholder="'0000/00/00'"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.collectionDate.isValid"
                    :errMsg.sync="validate.collectionDate.errMsg"
                  />
                  <InputTime
                    class="sm"
                    :value.sync="formValue.collectionTime"
                    :placeholder="'00:00'"
                    :validation="validateJustBlank"
                  />
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>引き渡し担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      id="collection-select"
                      type="radio"
                      :value="true"
                      v-model="isInputForm"
                      v-on:change="changeGenerateMemberInputForm"
                    />
                    <label class="c-radio__label" for="collection-select">
                      <span class="c-radio__box"></span>リストから選択
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="collection-input"
                      type="radio"
                      :value="false"
                      v-model="isInputForm"
                      v-on:change="changeGenerateMemberInputForm"
                    />
                    <label class="c-radio__label" for="collection-input">
                      <span class="c-radio__box"></span>直接入力
                    </label>
                  </div>
                </div>
                <div class="c-inputWrap__items__cols">
                  <Selectbox
                    v-if="isInputForm"
                    name="emit-in-charge"
                    :value.sync="formValue.manifestInfo.generateMember.id"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in memberDisOpt"
                      :key="'mp' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <InputText
                    v-if="isInputForm == false"
                    style="width: 22rem"
                    placeholder="入力してください"
                    tooltip="12文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="12"
                    inputType="text"
                    input_id="input_registerMember"
                    :value.sync="formValue.manifestInfo.generateMember.name"
                    :isValid.sync="validate.generateMemberName.isValid"
                    :errMsg.sync="validate.generateMemberName.errMsg"
                  />
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <Selectbox
                    class=""
                    name="register-in-charge"
                    :value.sync="formValue.manifestInfo.registMemberId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in memberOpt"
                      :key="'mp' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required c-infoText fixed_width">
                <div class="c-infoText__text help">
                  <label>番号取得方法</label>
                </div>
                <ManifestNoTip />
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      id="use-acquired-no-true"
                      type="radio"
                      :value="true"
                      v-model="formValue.isUseAcquiredManifestNo"
                    />
                    <label class="c-radio__label" for="use-acquired-no-true">
                      <span class="c-radio__box"></span>取得済み番号利用
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="use-acquired-no-false"
                      type="radio"
                      :value="false"
                      v-model="formValue.isUseAcquiredManifestNo"
                    />
                    <label class="c-radio__label" for="use-acquired-no-false">
                      <span class="c-radio__box"></span>番号新規取得
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 廃棄物情報 -->
        <div class="l-contents l-area">
          <div class="l-box">
            <h3 class="c-lead icon">廃棄物情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>ルート</label>
              </div>

              <div class="c-inputWrap__items" :class="{ disabled: !formValue.generateStoreId }">
                <div class="non_side_layout">
                  <div
                    class="c-btn secondary small l-item"
                    @click="showRouteSelectModal()"
                  >
                    ルート選択
                  </div>
                </div>
              </div>
            </div>

            <ErrorMessage :errMsgs="errMsgsRoute" />
            <ErrorMessage :errMsgs="errMsgsRouteCount" />

            <template v-if="formValue.routes.length > 0">
              <div
                class="l-block-lg"
                v-for="(route, route_index) in formValue.routes"
                :key="`route_${route_index}`"
              >
                <div class="l-flex -between c-text sub-title -strong -lg">
                  <p class="c-text horizontal_dot">
                    {{ !routeInfo(route.routeId) ? '' : routeInfo(route.routeId).routeName }}
                  </p>
                  <div>
                    <div
                      class="c-input__addBtn c-btn secondary delete sm"
                      @click="removeRouteHandler(route.routeId)"
                      style="min-width: 74px;"
                    >
                      削除
                    </div>
                  </div>
                </div>
                <div class="l-area-side">
                  <div class="c-inputWrap bottom_xs space_between flex_wrap">
                    <div v-if="routeBasicInfo(route.routeId)">
                      <dl class="c-dl">
                        <dt>廃棄物種類：</dt>
                        <dd>{{ routeBasicInfo(route.routeId).jwnetItemTypeName }}</dd>
                      </dl>
                      <dl class="c-dl">
                        <dt>排出事業場：</dt>
                        <dd>{{ routeBasicInfo(route.routeId).routeCompanyName.generateStore }}</dd>
                      </dl>
                      <dl class="c-dl">
                        <dt>運搬業者：</dt>
                        <dd>{{ routeBasicInfo(route.routeId).routeCompanyName.collectCompany }}</dd>
                      </dl>
                      <dl class="c-dl">
                        <dt>処分場：</dt>
                        <dd>{{ routeBasicInfo(route.routeId).routeCompanyName.disposalSite }}</dd>
                      </dl>
                      <dl v-for="alert in routePriorConsultationAlertMsgs.filter((msg) => msg.routeId === route.routeId)" v-bind:key="alert" class="c-dl">
                        <span v-if="alert.routePriorconsultationIsLimit" class="c-text icon icon-before danger">{{ alert.msg }}</span>
                        <span v-if="alert.routePriorconsultationIsAlert" class="c-text icon icon-before warning">{{ alert.msg }}</span>
                      </dl>
                    </div>
                  </div>

                  <!-- 事業場変更可能フラグがONのルートを選択時   -->
                  <template v-if="routeInfo(route.routeId) && routeInfo(route.routeId).isChangeStore">
                    <div class="c-inputWrap flex_wrap">
                      <div>
                        <div class="c-inputWrap__label">
                          <label>排出事業場名</label>
                        </div>
                        <div>
                          <InputText
                            :value.sync="route.anyGenerateStoreInfo.anyGenerateStoreName"
                            inputTyle="text"
                            :validation="validateMaxLen"
                            tooltip="60文字以内で入力してください"
                            :validateArg="60"
                            :isValid.sync="validate.routes[route.routeId].anyGenerateStoreName.isValid"
                            :errMsg.sync="validate.routes[route.routeId].anyGenerateStoreName.errMsg"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="c-inputWrap c-inputWrap__label">
                      <label>排出事業場住所</label>
                    </div>
                    <div class="c-inputWrap flex_wrap -anyGenerateStoreInfo_wrap">
                      <div class="c-inputWrap flex_wrap -anyGenerateStoreInfo">
                        <div>
                          <div class="c-inputWrap__label">
                            <label>郵便番号</label>
                          </div>
                          <div>
                            <div class="c-inputWrap__items__cols center sm-mgn">
                              <InputText
                                :value.sync="route.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode"
                                inputTyle="number"
                                placeholder="1234567(ハイフンなし)"
                                tooltip="7桁の半角数字で入力してください。"
                                :validation="validateNumberEqualLen"
                                :validateArg="7"
                                :isValid.sync="validate.routes[route.routeId].zipCode.isValid"
                                :errMsg.sync="validate.routes[route.routeId].zipCode.errMsg"
                              />
                              <div
                                class="c-btn secondary sm"
                                @click="searchAddressHandler(route.routeId)"
                              >
                                住所自動入力
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="c-inputWrap__label">
                            <label>都道府県</label>
                          </div>
                          <div>
                            <div>
                              <Selectbox
                                class=""
                                name="prefecture"
                                :value.sync="selectedPrefectureTemp.find(pref => pref.routeId === route.routeId).pref"
                                :validation="validateJustBlank"
                                :isValid.sync="validate.routes[route.routeId].prefecture.isValid"
                                :errMsg.sync="validate.routes[route.routeId].prefecture.errMsg"
                              >
                                <option
                                  v-for="prefecture in prefectures"
                                  :key="prefecture.id"
                                  :value="prefecture.name"
                                >
                                  {{ prefecture.name }}
                                </option>
                              </Selectbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="c-inputWrap flex_wrap -anyGenerateStoreInfo">
                        <div>
                          <div class="c-inputWrap__label">
                            <label>市区町村</label>
                          </div>
                          <div>
                            <div>
                              <InputText
                                class="lg"
                                :value.sync="route.anyGenerateStoreInfo.anyGenerateStoreAddress.address"
                                inputTyle="text"
                                placeholder="市区町村"
                                tooltip="100文字以内で入力してください"
                                :validation="validateMaxLen"
                                :validateArg="100"
                                :isValid.sync="validate.routes[route.routeId].address.isValid"
                                :errMsg.sync="validate.routes[route.routeId].address.errMsg"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="c-inputWrap__label">
                            <label>番地建物名号室</label>
                          </div>
                          <div>
                            <div>
                              <InputText
                                class="lg"
                                :value.sync="route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName"
                                inputTyle="text"
                                placeholder="番地建物名号室"
                                tooltip="40文字以内で入力してください"
                                :validation="validateMaxLen"
                                :validateArg="40"
                                :isValid.sync="validate.routes[route.routeId].buildingName.isValid"
                                :errMsg.sync="validate.routes[route.routeId].buildingName.errMsg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <div class="c-inputWrap flex_wrap">
                    <div>
                      <span class="c-inputWrap__label"><label>廃棄物名称</label></span>
                      <Selectbox
                        name="disposal-kind"
                        :value.sync="route.wasteInfo.wasteNameId"
                        :validation="validateJustBlank"
                      >
                        <option :value="null"></option>
                        <option
                          v-for="(wasteName, index) in findArrByRouteId(route.routeId, 'wasteName')"
                          :key="'waste-name' + index"
                          :value="wasteName.id"
                        >
                          {{ wasteName.name }}
                        </option>
                      </Selectbox>
                    </div>
                    <div>
                      <span class="c-inputWrap__label"><label>詳細名称</label></span>
                      <InputText
                        :class="'xlg_md'"
                        :value.sync="route.wasteInfo.detailWasteName"
                        placeholder="詳細名称"
                        tooltip="30文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="30"
                        :isValid.sync="validate.routes[route.routeId].detailWasteName.isValid"
                        :errMsg.sync="validate.routes[route.routeId].detailWasteName.errMsg"
                      />
                    </div>
                    <div>
                      <span class="c-inputWrap__label"><label>処分方法</label></span>
                      <Selectbox
                        name="package-name"
                        :value.sync="route.wasteInfo.disposalWayTypeId"
                        :validation="validateJustBlank"
                      >
                        <option :value="null"></option>
                        <option
                          v-for="(opt, index) in findArrByRouteId(route.routeId, 'disposalWayTypes')"
                          :key="'dwt' + index"
                          :value="opt.id"
                        >
                          {{ opt.name }}
                        </option>
                      </Selectbox>
                    </div>
                    <div>
                      <span class="c-inputWrap__label"><label>廃棄物数量</label></span>
                      <div class="c-inputWrap__items__cols">
                        <InputText
                          class="mid"
                          inputType="number"
                          :value.sync="route.wasteInfo.wasteQuantity"
                          tooltip="廃棄物数量は数字で入力してください。"
                          :validation="validateNumberOrFloat"
                          :isValid.sync="validate.routes[route.routeId].wasteQuantity.isValid"
                          :errMsg.sync="validate.routes[route.routeId].wasteQuantity.errMsg"
                        />
                        <Selectbox
                          class="sm"
                          name="unit"
                          :value.sync="route.wasteInfo.wasteUnitTypeId"
                          :validation="validateJustBlank"
                          :isValid.sync="validate.routes[route.routeId].wasteUnitTypeId.isValid"
                          :errMsg.sync="validate.routes[route.routeId].wasteUnitTypeId.errMsg"
                        >
                          <option :value="null"></option>
                          <option
                            v-for="(opt, index) in wasteUnitOpt"
                            :key="'wu' + index"
                            :value="opt.id"
                          >
                            {{ opt.name }}
                          </option>
                        </Selectbox>
                      </div>
                    </div>
                    <div>
                      <span class="c-inputWrap__label"><label>荷姿</label></span>
                      <Selectbox
                        name="package-name"
                        formSize="mid"
                        :value.sync="route.wasteInfo.shapeTypeId"
                        :validation="validateJustBlank"
                      >
                        <option :value="null"></option>
                        <option
                          :key="'shp' + index"
                          v-for="(opt, index) in findArrByRouteId(route.routeId, 'shapes')"
                          :value="opt.id"
                        >
                          {{ opt.name }}
                        </option>
                      </Selectbox>
                    </div>
                    <div>
                      <span class="c-inputWrap__label"><label>荷姿数量</label></span>
                      <InputText
                        :class="'sm'"
                        :value.sync="route.wasteInfo.shapeQuantity"
                        inputType="number"
                        :validation="validateJustBlank"
                        :isValid.sync="validate.routes[route.routeId].shapeQuantity.isValid"
                        :errMsg.sync="validate.routes[route.routeId].shapeQuantity.errMsg"
                      />
                    </div>
                    <div>
                      <span class="c-inputWrap__label"><label>数量確定者</label></span>
                      <Selectbox
                        name="number-in-charge"
                        formSize="mid"
                        :value.sync="route.wasteInfo.quantityConfirmBusinessTypeId"
                        :validation="validateJustBlank"
                      >
                        <option :value="null"></option>
                        <option
                          v-for="(opt, index) in confirmMemberOpt[route.routeId]"
                          :key="'cm' + index"
                          :value="opt.id"
                        >
                          {{ opt.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>

                  <div v-if="isDisplayHarmfulSelection(route.routeId)" class="c-inputWrap flex_wrap">
                    <div class="c-inputWrap__label flex full">
                      <label>有害物質情報</label>
                    </div>
                    <div class="c-inputWrap__items__cols wrap full left_sm">
                      <div
                        v-for="(harmfulItem, itemIndex) in route.harmfulInfo"
                        :key="`hmfl-item-${itemIndex}`"
                        class="l-flex start"
                      >
                        <Selectbox
                          class="l-area"
                          name="package-name"
                          :value.sync="harmfulItem.harmfulTypeId"
                          :validation="validateJustBlank"
                        >
                          <option :value="null"></option>
                          <option
                            v-for="(harmfulType, index) in harmfulTypes"
                            :key="'hmfl-' + index"
                            :value="harmfulType.id"
                          >
                            {{ harmfulType.name }}
                          </option>
                        </Selectbox>
                        <div
                          class="c-input__addBtn c-btn secondary delete sm"
                          @click="removeHarmfulTypeHandler(harmfulItem, route.routeId)"
                        >
                          削除
                        </div>
                      </div>
                      <div
                        class="c-btn secondary add sm"
                        v-if="showAddBtn(route.harmfulInfo, 'harmful')"
                        @click="addHarmfulTypeHandler(route.routeId)"
                      >
                        有害物質名を追加
                      </div>
                    </div>
                  </div>
                  <div class="c-inputWrap flex_wrap bottom_xs">
                    <div class="c-inputWrap__label flex full">
                      <label>連絡番号</label>
                    </div>
                    <div class="c-inputWrap__items__cols wrap full left_sm">
                      <div
                        v-for="(contact, contactIndex) in route.contactNoInfo.contactNoList"
                        :key="`ctct-item-${contactIndex}`"
                        class="l-flex start"
                      >
                        <InputText
                          class="l-area"
                          :value.sync="contact.contactNo"
                          inputTyle="text"
                          :validation="validateJustBlank"
                          :isValid.sync="validate.routes[route.routeId].contactNo[contactIndex].isValid"
                          :errMsg.sync="validate.routes[route.routeId].contactNo[contactIndex].errMsg"
                        />
                        <div
                          class="c-input__addBtn c-btn secondary delete sm"
                          @click="removeContactNoHandler(contact, contactIndex, route.routeId)"
                        >
                          削除
                        </div>
                      </div>
                      <div
                        v-if="showAddBtn(route.contactNoInfo.contactNoList, 'contact')"
                        class="c-btn secondary add sm"
                        @click="addContactNoHandler(route.routeId)"
                      >
                        連絡番号を追加
                      </div>
                    </div>
                  </div>
                  <div class="c-inputWrap flex_wrap bottom_xs">
                    <div class="c-inputWrap__label flex full">
                      <label>備考</label>
                    </div>
                    <div class="c-inputWrap__items__cols wrap full left_sm">
                      <div
                        class="l-flex start"
                        v-for="(remarks, remarkIndex) in route.remarksInfo.remarksList"
                        :key="'remarks' + remarkIndex"
                      >
                        <InputText
                          class="l-area xlg_md"
                          tooltip="備考は50バイト以内で入力してください。"
                          :value.sync="remarks.remarks"
                          inputType="text"
                          :validation="validateMaxLen"
                          :validateArg="50"
                          :isValid.sync="validate.routes[route.routeId].remarks[remarkIndex].isValid"
                          :errMsg.sync="validate.routes[route.routeId].remarks[remarkIndex].errMsg"
                        />
                        <div
                          class="c-input__addBtn c-btn secondary delete sm"
                          @click="removeTextareaHandler(remarks, route.routeId)"
                        >削除
                        </div>
                      </div>
                      <div
                        class="c-btn secondary func add"
                        v-if="showAddBtn(route.remarksInfo.remarksList, 'remarks')"
                        @click="addTextareaHandler(route.routeId)"
                      >
                        備考を追加
                      </div>
                    </div>
                  </div>

                  <!-- WDS -->
                  <div v-if="routeInfo(route.routeId) && routeInfo(route.routeId).wdsFileName" class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>WDS</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <a class="c-text text-link c-entrustText__text" @click="downloadWDS(routeInfo(route.routeId))">
                        {{ routeInfo(route.routeId).wdsFileName }}
                      </a>
                    </div>
                  </div>

                  <!-- ルートラベル・ノート -->
                  <div class="c-inputWrap flex_wrap">
                    <!-- TODO: テスト用 コメント 削除する -->
                    <!-- v-for="(routeLabel, routeLabelIndex) in [{header: 'ラベルヘッダーラベルヘッダーラベルヘッダーラベルヘッダーラベルヘッダーラベルヘッダー', labelValue: 'バリューバリューバリューバリューバリューバリューバリューバリュー'},{header: 'ラベルヘッダーラベルヘッダーラベルヘッダーラベルヘッダーラベルヘッダーラベルヘッダーラベルヘッダーラベルヘッダー', labelValue: 'バリューバリューバリューバリューバリューバリュー', labelUnit: 'kg'}]" -->
                    <div
                      v-for="(routeLabel, routeLabelIndex) in !route.labelInfo ? [] : route.labelInfo.labels"
                      :key="'label-' + routeLabelIndex"
                    >
                      <span class="c-inputWrap__label">
                        <label>
                          {{ dottedText(routeLabel.header, 20) }}
                        </label>
                      </span>
                      <div class="c-inputWrap__items__cols">
                        <InputText
                          :class="{lg: !routeLabel.labelUnit, sm: routeLabel.labelUnit}"
                          inputType="text"
                          :value.sync="routeLabel.labelValue"
                          :validation="validateJustBlank"
                        />
                        <span
                          v-if="routeLabel.labelUnit"
                          style="width: 80px;"
                        >
                          {{ dottedText(routeLabel.labelUnit, 10) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- /.l-contents -->
      </div>

      <FloatButtons>
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="checkRequiredHandler">登録</div>
        </div>
      </FloatButtons>
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを予約します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="submit">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!-- TODO: 長いのでコンポーネント分けたい -->
    <!--ルート選択モーダル-->
    <div class="c-modal route_xlg height_xlg" v-if="isRouteSelectModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">ルート選択</h5>
          <span
            class="c-modal__head__close"
            @click="isRouteSelectModalShow = false"
          ></span>
        </div>
        <div class="c-modal__body">
          <!--検索条件開始-->
          <div>
            <div class="c-inputWrap flex_wrap">
              <!--廃棄物区分条件-->
              <div>
                <span class="text-label">廃棄物種類</span>
                <Selectbox
                  class="mid"
                  :value.sync="routeModalSearchParam.jwnetItemTypeId"
                  :validation="validateJustBlank"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="(opt, index) in jwnetItemsOpt"
                    :key="`jwnet-opt-${index}`"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
              <!-- ルート名称 -->
              <div>
                <span class="text-label">ルート名称</span>
                <InputText
                  class="md_l"
                  :value.sync="routeModalSearchParam.routeName"
                  inputType="text"
                  placeholder="ルート名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <!-- 廃棄物名称 -->
              <div>
                <span class="text-label">廃棄物名称</span>
                <InputText
                  class="md_l"
                  :value.sync="routeModalSearchParam.wasteName"
                  inputType="text"
                  placeholder="廃棄物名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <!--マニフェスト区分条件-->
              <div>
                <span class="text-label">マニフェスト区分</span>
                <div class="l-flex cols">
                  <div class="c-input" v-for="(manifestType, manifestTypeIndex) in manifestTypeOpt" :key="'manifestType-' + manifestTypeIndex">
                    <div class="c-checkbox">
                      <input
                        :id="'manifestType-' + manifestTypeIndex"
                        type="checkbox"
                        v-model="routeModalSearchParam.manifestTypeIdList"
                        :value="manifestType.id"
                      />
                      <label class="c-checkbox__label" :for="'manifestType-' + manifestTypeIndex">
                        <span class="c-checkbox__box"></span>
                        {{ manifestType.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- お気に入り -->
              <div>
                <span class="text-label">お気に入り</span>
                <div class="l-flex cols">
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                        id="is-favorite"
                        type="checkbox"
                        v-model="routeModalSearchParam.isFavorite"
                      />
                      <label class="c-checkbox__label" for="is-favorite">
                        <span class="c-checkbox__box"></span>
                        有
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 運搬事業者 -->
              <div>
                <span class="text-label">運搬事業者</span>
                <v-autocomplete ref="collect_autocomplete" @change="s => {onChange(s, 'collect_autocomplete')}" placeholder="運搬事業者名称を入力" class="c-input md_l" :items="this.selectGeneratorCollectList" :min-len="0" :wait="300" @update-items='updateGeneratorCollectList' v-model="selectedGeneratorCollect" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              </div>
              <!-- 処分事業者 -->
              <div>
                <span class="text-label">処分事業者</span>
                <v-autocomplete ref="disposal_autocomplete" @change="s => {onChange(s, 'disposal_autocomplete')}" placeholder="処分事業者名称を入力" class="c-input md_l" :items="this.selectGeneratorDisposalList" :min-len="0" :wait="300" @update-items='updateGeneratorDisposalList' v-model="selectedGeneratorDisposal" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              </div>
              <!-- 処分場 -->
              <div>
                <span class="text-label">処分場</span>
                <v-autocomplete ref="disposal_site_autocomplete" @change="s => {onChange(s, 'disposal_site_autocomplete')}" placeholder="処分場名称を入力" class="c-input md_l" :items="this.selectDisposalSiteList" :min-len="0" :wait="300" @update-items='updateDisposalSiteList' v-bind:initItems="disposalSiteList" v-model="selectedDisposalSite" :component-item='selectItemShowNameAndJwnetIdTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabelDisposalSite'>
                </v-autocomplete>
              </div>
              <!--廃棄物区分条件-->
              <div>
                <span class="text-label"></span>
                <div class="l-flex cols">
                  <div class="c-input" v-for="(wasteTypeId, wtIndex) in wasteTypeIdOpt" :key="'wasteTypeId-' + wtIndex">
                    <!-- 産業廃棄物 -->
                    <template v-if="wasteTypeId.id === DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE">
                      <div class="c-checkbox">
                        <div class="flex_wrap">
                          <div v-for="(industrialWasteType, industrialWasteTypeIndex) in industrialWasteJwnetTypeOpt" :key="'industrialWasteType-' + industrialWasteTypeIndex">
                            <input
                              :id="'industrialWasteType-' + industrialWasteTypeIndex"
                              type="checkbox"
                              v-model="routeModalSearchParam.industrialWasteJwnetTypeIdList"
                              :value="industrialWasteType.id"
                            />
                            <label class="c-checkbox__label" :for="'industrialWasteType-' + industrialWasteTypeIndex">
                              <span class="c-checkbox__box"></span>
                              {{ industrialWasteType.name }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="c-checkbox">
                        <input
                          :id="'wasteTypeId-' + wtIndex"
                          type="checkbox"
                          v-model="routeModalSearchParam.wasteTypeIdList"
                          :value="wasteTypeId.id"
                        />
                        <label class="c-checkbox__label" :for="'wasteTypeId-' + wtIndex">
                          <span class="c-checkbox__box"></span>
                          {{ wasteTypeId.name }}
                        </label>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div><!-- ./c-inputWrap flex_wrap -->

            <div class="l-container errMsg_float_wrap">
              <ErrorMessage :errMsgs="errMsgsRouteModal" class="errMsg_float"/>
              <div class="l-btns l-block" style="padding-left: 3rem;">
                <div class="c-btn secondary small" @click="resetRouteModalSearchHandler">
                  条件をリセット
                </div>
                <div class="c-btn primary small" @click="routeModalSearchHandler">検索</div>
              </div>
            </div>
          </div>

          <!--検索条件終了-->

          <!--検索結果表示開始-->
          <div class="c-infoList__wrap fit">
            <div class="c-infoList l-box -border">
              <div class="c-infoList__row head sticky">
                <!-- 全選択/解除 -->
                <div class="c-infoList__cell cb left_sticky">
                  <div class="c-checkbox">
                    <input
                      id="check-all-manifests"
                      type="checkbox"
                      v-model="isAllChecked"
                      @change="changeAllCheckHandler"
                    />
                    <label class="c-checkbox__label" for="check-all-manifests">
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>
                <div class="c-infoList__cell md">ルート名称</div>
                <div class="c-infoList__cell sm">廃棄物区分</div>
                <div class="c-infoList__cell sm-m">廃棄物種類</div>
                <div class="c-infoList__cell sm-m">廃棄物名称</div>
                <div class="c-infoList__cell md_m">運搬事業者</div>
                <div class="c-infoList__cell lg">処分場</div>
              </div>
              <label
                v-for="item of this.narrowedRoutesList"
                :key="'route' + item.routeId"
                class="c-infoList__row list variable_height"
                :class="{'checked': selectedRouteIdsTemp.includes(item.routeId)}"
                :for="'route' + item.routeId"
              >
                <div class="c-infoList__cell cb left_sticky">
                  <div class="c-checkbox">
                    <input
                      :id="'route' + item.routeId"
                      type="checkbox"
                      :value="item.routeId"
                      v-model="selectedRouteIdsTemp"
                      :disabled="isDisabledCheckbox(item.routeId)"
                    />
                    <label class="c-checkbox__label" :for="'route' + item.routeId">
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>
                <div class="c-infoList__cell md">
                  <div class="c-text vertical_dot">{{ item.routeName }}</div>
                </div>
                <div class="c-infoList__cell sm">
                  <div class="c-text horizontal_dot">{{ item.wasteType.name }}/{{ item.manifestType.id === 1 ? "電子" : "紙" }}</div>
                </div>
                <div class="c-infoList__cell sm-m">
                  <div class="c-text horizontal_dot">{{ item.jwnetItemType.name }}</div>
                </div>
                <div class="c-infoList__cell sm-m">
                  <div class="c-text horizontal_dot">{{ item.jwnetItemType.wasteName }}</div>
                </div>
                <div class="c-infoList__cell md_m">
                  <CommaText :items="item.collectCompanys" arg="name" :maxLength="14" />
                </div>
                <div class="c-infoList__cell lg">
                  <div class="c-text vertical_dot">{{ item.disposalSite.name + " JWNETID:" + item.jwnetId }}</div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <button
            class="c-btn primary small"
            :class="{ disabled: isDisabledRouteModal }"
            @click="clickSetRoute(selectedRouteIdsTemp)"
          >
            OK
          </button>
        </div>
      </div>
    </div>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      @selectAddress="selectAddressHandler"
    />

    <AppFooter />
  </div>
</template>

<style scoped lang="scss">
.errMsg_float_wrap {
  position: relative;
  margin-left: -3rem;
  .errMsg_float {
    position: absolute;
    bottom: 0;
    margin: 0;
  }
}
</style>

<script>
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import InputTime from "@/components/parts/input/InputTime";
import AddressSelectModal from "./components/AddressSelectModal.vue";
import validation from "@/mixin/validation";
import reserveFormInfo from "./mixins/reserveFormInfo";
import callApi from "./mixins/callApi";
import recurrying from "./mixins/recurrying";
import harmfulType from "./mixins/harmfulType";
import contactNo from "./mixins/contactNo";
import addressSearch from "./mixins/addressSearch";
import route from "./mixins/route";
import textarea from "./mixins/textarea";
import validate from "./mixins/validate";
import common from "@/mixin/common";
import { API_URL, WEEK_OF_DAY, DOMAIN, COOKIE_INFO, GENERATE_MEMBER_WAY_TYPE} from "@/const";
import { MANIFEST_MULTIPLE_SET_ROUTE_MAX } from "@/const/index";
import axios from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import FloatButtons from "@/components/layout/FloatButtons";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import CommaText from "@/components/parts/CommaText";
import ManifestNoTip from "../components/ManifestNoTip.vue";
import SelectItemShowNameAndJwnetIdTemplate from "@/components/parts/input/SelectItemShowNameAndJwnetIdTemplate";

export default {
  name: "manifest-multiple-input",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    InputDate,
    InputTime,
    AddressSelectModal,
    FloatButtons,
    CommaText,
    ManifestNoTip,
  },
  mixins: [
    validation,
    reserveFormInfo,
    callApi,
    recurrying,
    harmfulType,
    contactNo,
    addressSearch,
    route,
    textarea,
    validate,
    common,
    selectionLocalStorage
  ],
  data() {
    return {
      autoSelect: false,
      selectTemplate: SelectItemTemplate,
      selectItemShowNameAndJwnetIdTemplate: SelectItemShowNameAndJwnetIdTemplate,
      selectedGeneratorCollect : '', //選択した運搬事業者
      selectedGeneratorDisposal: '', //選択した処分事業者
      selectedDisposalSite: '', //選択した処分場
      selectedGenerateStore : '', //選択した排出事業場
      DOMAIN: DOMAIN,
      isAllChecked: false,
      selectedRouteIdsTemp: [], // ルート選択モダールにチェックしてたルートIDリスト（OKボタンを押さない）
      preSelectedRouteIds: [], //前回選択したルートIDリスト
      selectedRouteIds: [], // OKボタンを押下した後、選択したルートIDリスト
      isRecurryingModalShow: false,
      isRouteSelectModalShow: false,
      isConfirmModalShow: false,
      recurryingType: DOMAIN.RECURRING_TYPE,
      weekOfDayTypeIdList: [],
      weekOfDay: WEEK_OF_DAY,
      errMsgs: [],
      errMsgsRouteModal: [],
      errMsgsRoute: [],
      errMsgsRouteCount: [],
      selectedJwnetItem: {},
      selectedPrefectureTemp: [], // [{routeId: xx, pref: xx}]
      processing: false,
      wasteTypeIdOpt: [
        { id: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE , name: "産業廃棄物" },
        { id: DOMAIN.WASTE_TYPE.VALUABLES , name: "有価物" },
        { id: DOMAIN.WASTE_TYPE.COMMON_WASTE , name: "一般廃棄物" },
      ],
      industrialWasteJwnetTypeOpt: [
        { id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_NOT_SPECIAL_INDUSTRIAL_WASTE , name: "普通産廃" },
        { id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE , name: "特管産廃" },
      ],
      manifestTypeOpt: [
        { id: DOMAIN.MANIFEST_TYPE.DIGITAL , name: "電子" },
        { id: DOMAIN.MANIFEST_TYPE.ANALOG , name: "紙" },
      ],
      // ルート選択肢モーダル検索パラメータ
      routeModalSearchParam: {
        jwnetItemTypeId: "", // 廃棄物種類ID
        routeName: "", // ルート名称
        wasteName: "", // 廃棄物名称
        collectCompanyId: null, // 運搬事業者Id
        disposalCompanyId: null, // 処分事業者Id
        disposalSiteId: null, //処分場Id
        wasteTypeIdList: [],//廃棄物区分
        manifestTypeIdList: [], // マニフェスト区分
        industrialWasteJwnetTypeIdList: [],
        isFavorite: false, // お気に入りフラグ
      },
      isInputForm: true,
      generateMemberWayType: GENERATE_MEMBER_WAY_TYPE,
    };
  },
  methods: {
    updateGenerateStoreList(text) {
      if (!text || text.length === 0) {
        //全ての事業場の選択肢を表示する
        this.selectGenerateStoreList = this.generateStoreOpt
        return
      }
      this.selectGenerateStoreList = this.generateStoreOpt.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    onChange(queryString, ref) {
      this.$nextTick(() => {
        this.$refs[ref].searchText = queryString;
      });
    },
    /**
     * ルート全件チェックボックス
     *
     * NOTE:
     * 自動で全選択解除になったときに
     * 意図せず全件チェックが外れてしまうので、computed, watchしない
     */
    changeAllCheckHandler() {
      if (this.isAllChecked) {
        const checkedRouteIds = this.selectedRouteIdsTemp.concat(); // コピー

        let defaultCheckedCount = checkedRouteIds.length;

        // NOTE: 無駄なループ回避のため、一旦20件切り出しておく
        const sumRouteIds = this.narrowedRoutesList.slice(0, 20).map(list => {

          // NOTE: 選択済みも含め10件に制限
          if (defaultCheckedCount >= MANIFEST_MULTIPLE_SET_ROUTE_MAX) return null;

          const existChecked = checkedRouteIds.includes(list.routeId);

          // 選択済みならスキップ
          if (existChecked) return null;

          defaultCheckedCount += 1;
          return list.routeId;
        }).filter(routeId => routeId !== null);

        this.selectedRouteIdsTemp = [...checkedRouteIds, ...sumRouteIds];
      } else {
        this.selectedRouteIdsTemp = [];
      }
    },

    // ルート削除
    removeRouteHandler(routeId) {
      const filterRoute = (targetRouteId) => targetRouteId !== routeId;

      // チェックマークから外す
      this.isAllChecked = false;

      // 選択中のチェックボックスから外す
      this.selectedRouteIdsTemp = this.selectedRouteIdsTemp.filter(selectedRouteId => filterRoute(selectedRouteId));
      this.preSelectedRouteIds = this.selectedRouteIdsTemp;

      // 選択中のルート情報から外す
      this.selectedRouteIds = this.selectedRouteIds.filter(selectedRoute => filterRoute(selectedRoute.routeId));

      // APIから取得したルート情報から外す
      this.routeDetailInfos = this.routeDetailInfos.filter(route => filterRoute(route.routeId));

      // フォームから外す
      this.formValue.routes = this.formValue.routes.filter(route => filterRoute(route.routeId));
      this.formValue.manifestInfo.routeIds = this.formValue.manifestInfo.routeIds.filter(_routeId => filterRoute(_routeId));

      // バリデーションから外す
      delete this.validate.routes[routeId];
    },

    /**「ルート選択」ボタンを押すと、選択モーダルを表示する */
    showRouteSelectModal() {
      // 設定した条件があればそのままにする、ない場合、default値で設定
      this.setRouteSelectModalSearchParam();
      this.isRouteSelectModalShow = true;
      this.routeModalSearchHandler();
    },

    /** 設定した条件があればそのままにひょうじする */
    setRouteSelectModalSearchParam() {
      const currentSearchParam = this.routeModalSearchParam;
      this.routeModalSearchParam = {
        jwnetItemTypeId: currentSearchParam.jwnetItemTypeId !== "" ? currentSearchParam.jwnetItemTypeId : "",
        routeName: currentSearchParam.routeName !== "" ? currentSearchParam.routeName : "",
        wasteName: currentSearchParam.wasteName !== "" ? currentSearchParam.wasteName : "",
        collectCompanyId: currentSearchParam.collectCompanyId,
        disposalCompanyId: currentSearchParam.disposalCompanyId,
        disposalSiteId: currentSearchParam.disposalSiteId,
        wasteTypeIdList: currentSearchParam.wasteTypeIdList.length > 0 ? currentSearchParam.wasteTypeIdList : [],
        manifestTypeIdList: currentSearchParam.manifestTypeIdList.length > 0 ? currentSearchParam.manifestTypeIdList : [],
        industrialWasteJwnetTypeIdList: currentSearchParam.industrialWasteJwnetTypeIdList.length > 0 ? currentSearchParam.industrialWasteJwnetTypeIdList : [],
        isFavorite: currentSearchParam.isFavorite !== false ? currentSearchParam.isFavorite : false,
      }
    },
    /** ルート選択肢モーダルに「検索条件をリセット」ボタンを押すと */
    initRouteModalSearchParam() {
      this.selectedGeneratorCollect = ''
      this.selectedGeneratorDisposal = ''
      this.selectedDisposalSite = ''
      this.disposalSiteList = this.disposalSiteOptions;
      this.routeModalSearchParam = {
        jwnetItemTypeId: "",
        routeName: "",
        wasteName: "",
        collectCompanyId: null,
        disposalCompanyId: null,
        dipsosalSiteId: null,
        wasteTypeIdList: [],//
        manifestTypeIdList: [], // マニフェスト区分
        industrialWasteJwnetTypeIdList: [],
        isFavorite: false,
      }
    },

    // ルート選択肢モーダルの検索情報をクリアする
    resetRouteModalSearchHandler(){
      this.initRouteModalSearchParam()
    },

    // ルート選択肢モーダルに「検索」ボタンを押す処理
    routeModalSearchHandler() {
      this.getRouteListSearchApi().then(() => {
        this.updateCheckedAllRoute()
      });
    },

    getFirstCollectionDateWeekOfDayLabel() {
      const target = new Date(this.formValue.collectionDate);
      return WEEK_OF_DAY[target.getDay()].label;
    },

    /** ルート確定 */
    clickSetRoute(routeIds) {
      // NOTE: 1ルート以上選択必須とする
      if (routeIds.length === 0) {
        this.errMsgsRouteModal = ['ルートを1つ以上選択してください'];
        return;
      }

      this.errMsgsRouteModal = [];

      this.formValue.manifestInfo.routeIds = routeIds;
      this.onChangeRouteHandler(routeIds);

      this.isRouteSelectModalShow = false;
    },

    initCustomRecurryin() {
      this.formValue.recurringInfo.interval = "";
      this.formValue.recurringInfo.recurringTypeId = "";
      this.formValue.recurringInfo.weekOfDayTypeId = [];
    },

    // 画面内下部の登録押下
    checkRequiredHandler() {
      this.errMsgs = [];
      this.runValidate().then((isValidForm) => {
        if (!isValidForm) {
          this.processing = false;
          this.errMsgs.push("入力に誤りがあります。");

          // ルート件数
          const routeCountErrMsg = this.validate.routesCount.errMsg;
          if (!routeCountErrMsg) this.errMsgsRouteCount = [];
          else this.errMsgsRouteCount = [routeCountErrMsg];

          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        this.isConfirmModalShow = true;
      });
    },

    // モーダル内の登録押下
    submit() {
      this.errMsg = [];
      this.processing = true; // 2重submit防止
      const postData = JSON.parse(JSON.stringify(this.formValue));

      // フォーム整形
      postData.manifestInfo.manifestStatusId = DOMAIN.MANIFEST_STATUS.UNDER_RESERVE;

      delete postData.manifestInfo.routeIds;

      if (!this.formValue.collectionTime) postData.collectionTime = null;

      postData.routes = postData.routes.map(route => {
        if (route.anyGenerateStoreInfo.anyGenerateStoreName.length === 0 &&
          route.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length === 0 &&
          route.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId.length === 0 &&
          route.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length === 0 &&
          route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length === 0
        ) {
          delete route.anyGenerateStoreInfo;
        } else {
          if (route.anyGenerateStoreInfo.anyGenerateStoreName &&
            route.anyGenerateStoreInfo.anyGenerateStoreName.length === 0) {
            delete route.anyGenerateStoreInfo.anyGenerateStoreName;
          }

          if (route.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length === 0 &&
            route.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId.length === 0 &&
            route.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length === 0 &&
            route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length === 0) {
            delete route.anyGenerateStoreInfo.anyGenerateStoreAddress;
          }
        }

        let anyGenerateStoreInfo = null;
        if (route.anyGenerateStoreInfo) {
          const anyGenerateStoreName = !route.anyGenerateStoreInfo.anyGenerateStoreName ? null : this.toFullWidthString(route.anyGenerateStoreInfo.anyGenerateStoreName);

          let anyGenerateStoreAddress = null;
          if (route.anyGenerateStoreInfo.anyGenerateStoreAddress) {

            const address = route.anyGenerateStoreInfo.anyGenerateStoreAddress.address;
            const noAddress = !address || address.length === 0;
            const addressObj = noAddress ? {} : { address: this.toFullWidthStringForAddressInfo(address) };

            const buildingName = route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName;
            const noBuildingName = !buildingName || buildingName.length === 0;
            const buildingNameObj = noBuildingName ? {} : { buildingName: this.toFullWidthStringForAddressInfo(buildingName) };

            const selectedPrefectureTemp = this.selectedPrefectureTemp;
            const prefTmpOfRoute = (!selectedPrefectureTemp || selectedPrefectureTemp.length < 1) ? null : selectedPrefectureTemp.find(prefTmp => {
                    return prefTmp.routeId === route.routeId;
                  });
            let prefecturesId = {};
            if (prefTmpOfRoute) {
              const prefecture = this.prefectures.find((prefecture) => {
                if (prefecture.name !== prefTmpOfRoute.pref) return false;
                return true;
              });
              if (prefecture) {
                prefecturesId = { prefecturesId: prefecture.id };
              }
            };

            anyGenerateStoreAddress = {
              zipCode: route.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode,
              ...prefecturesId,
              ...addressObj,
              ...buildingNameObj,
            }
          };

          anyGenerateStoreInfo = {
            anyGenerateStoreName,
            anyGenerateStoreAddress,
          }
        };

        // 数量確定者は設定できないものであれば、リセットする
        if (route.wasteInfo.quantityConfirmBusinessTypeId
            && !this.confirmMemberOpt[route.routeId].some(confirmMemberOpt => confirmMemberOpt.id === route.wasteInfo.quantityConfirmBusinessTypeId)){
          route.wasteInfo.quantityConfirmBusinessTypeId = null;
        }

        return {
          routeId: route.routeId,
          wasteInfo: {
            ...route.wasteInfo,
            // 詳細名称を全角に変換
            detailWasteName: !route.wasteInfo.detailWasteName ? null : this.toFullWidthString(route.wasteInfo.detailWasteName),
          },
          // harmfulInfoのharmfulTypeIdがnullなら削除
          harmfulInfo: route.harmfulInfo.filter(hi => hi.harmfulTypeId),
          // 備考
          remarksInfo: {
            remarksList: route.remarksInfo.remarksList.filter(r => r.remarks !== "").map(remarks => {
                return { remarks: this.toFullWidthString(remarks.remarks) }
              }),
          },
          // 連絡番号
          contactNoInfo: {
            contactNoList: route.contactNoInfo.contactNoList.filter(cn => cn.contactNo !== "").map(contactNo => {
                return { contactNo: contactNo.contactNo }
              }),
          },
          labelInfo: {
            labels: route.labelInfo.labels.map(label => {
              return { labelId: label.id, labelValue: label.labelValue };
            }),
          },
          anyGenerateStoreInfo,
          conversionFactorKg: route.conversionFactorKg,
          conversionFactorM3: route.conversionFactorM3,
          conversionFactorLiter: route.conversionFactorLiter,
          conversionFactorUnitNumber: route.conversionFactorUnitNumber,
        }
      });

      // 引き渡し担当者名の再設定
      // 選択肢の場合
      if (this.isInputForm) {
        if (!postData.manifestInfo.generateMember.id) {
          // IDがない場合、引き渡し担当者を削除
          delete postData.manifestInfo.generateMember
        } else {
          // IDがある場合、名称はIDで再設
          postData.manifestInfo.generateMember.name = this.memberOpt.filter(m => m.id == postData.manifestInfo.generateMember.id)[0].name;
        }
      } else {
        // 直接入力の場合
        // 名称がある場合、全角文字列に変換を行う
        postData.manifestInfo.generateMember.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(postData.manifestInfo.generateMember.name));
        if (!postData.manifestInfo.generateMember.name) {
          // 名称がない場合、引き渡し担当者を削除
          delete postData.manifestInfo.generateMember
        }
      }

      // ルート複数登録用、マニフェスト予約API
      // manifest/multipleRoute/create
      axios
        .post(API_URL.MANIFEST.MULTIPLE_CREATE, postData)
        .then(() => {
          // マニフェスト一覧へ
          // this.processing = false; // 2重submit解除
          this.$cookies.set(COOKIE_INFO.MESSAGE, "マニフェストを予約しました。");
          this.$router.push("/manifests/all");
        })
        .catch((err) => {
          this.processing = false; // 2重submit解除
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    generateStoreChangeHandler() {
      const generateStoreId = this.formValue.generateStoreId;
      if (generateStoreId) {
      this.getGenerateMemberGetWayApi(generateStoreId).then(() => {
        this.getMemberOptApi(generateStoreId).then(() => {
          // 担当者の初期値セット
          this.formValue.manifestInfo.registMemberId = this.$store.getters["userInfo/get"].id;
          if (this.generateMemberMetaInfo != null) {
            if (this.generateMemberMetaInfo.generateMemberGetWayMetaKey == this.generateMemberWayType.ID.metaKey) {
              this.isInputForm = true;
              this.formValue.manifestInfo.generateMember.id = Number(this.generateMemberMetaInfo.value);
              this.formValue.manifestInfo.generateMember.name = this.memberOpt.filter(m => m.id == this.formValue.manifestInfo.generateMember.id)[0].name;
            } else if (this.generateMemberMetaInfo.generateMemberGetWayMetaKey == this.generateMemberWayType.TEXT.metaKey) {
              this.isInputForm = false;
              this.formValue.manifestInfo.generateMember.id = "";
              this.formValue.manifestInfo.generateMember.name = this.generateMemberMetaInfo.value;
            }
          } else {
            this.isInputForm = true;
            this.formValue.manifestInfo.generateMember.id = this.$store.getters["userInfo/get"].id;
            this.formValue.manifestInfo.generateMember.name = this.memberOpt.filter(m => m.id == this.formValue.manifestInfo.generateMember.id)[0].name;
          }});
        });
      } else {
        // 担当者のクリア
        this.isInputForm = true;
        this.formValue.manifestInfo.registMemberId = ""
        this.formValue.manifestInfo.generateMember.id = ""
        this.formValue.manifestInfo.generateMember.name = ""
      }

      this.getEntrustJwnetItemsApi(generateStoreId);
      for (const routeId of this.selectedRouteIdsTemp) {
        this.removeRouteHandler(routeId)
      }
    },

    // 引き渡し担当者のラジオボタンを変更すると、引き渡し担当者をリセット
    changeGenerateMemberInputForm() {
      this.formValue.manifestInfo.generateMember.id = null;
      this.formValue.manifestInfo.generateMember.name = null;
      this.validate.generateMemberName =  { isValid: true, errMsg: "" };
    },

    downloadWDS(routeInfo) {
      axios
        .get(API_URL.MANIFEST.WDS_DOWNLOAD + routeInfo.routeId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, routeInfo.wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 戻るボタン押下
    backHandler() {
      this.$router.go(-1);
    },

    // ルートIDで各情報を探す
    findArrByRouteId(routeId, type) {
      let target = [];
      let arrName = '';
      switch (type) {
        case 'shapes':
          target = this.shapeOpt;
          arrName = 'shapes';
          break;
        case 'disposalWayTypes':
          target = this.disposalWayTypesOpt;
          arrName = 'disposalWayTypes';
          break;
        case 'wasteName':
          target = this.wasteNameOpt;
          arrName = 'wasteName';
          break;
        default:
          return [];
      }
      const data = target.find(r => r.routeId === routeId);
      if (!data) return [];
      return data[arrName];
    },


    setSelectedPrefectureTemp(routeIds) {
      if (routeIds.length < 1) return;
      const newSelectedPrefectureTemp = routeIds.map(routeId => {
        return {
          routeId,
          pref: '',
        }
      });

      this.selectedPrefectureTemp = [...this.selectedPrefectureTemp, ...newSelectedPrefectureTemp];
    },
    updateGeneratorCollectList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectGeneratorCollectList = this.generatorCollectList
        return
      }
      this.selectGeneratorCollectList = this.generatorCollectList.filter((item) => {
          return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorDisposalList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectGeneratorDisposalList = this.generatorDisposalList
        return
      }
      this.selectGeneratorDisposalList = this.generatorDisposalList.filter((item) => {
          return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalSiteList (text) {
      if (!text || text.length === 0) {
        //全ての処分場の選択肢を表示する
        this.selectDisposalSiteList = this.disposalSiteList
        return
      }
      this.selectDisposalSiteList = this.disposalSiteList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    getLabelDisposalSite (item) {
      if (item) {
        return item.name + "  JWNETID:"+ item.jwnetId;
      }
      return ''
    },
    // 全てルートチェックボックスの状態を判断する
    updateCheckedAllRoute() {
      // 10件 or 仮のルート一覧結果が全てチェックしたら、全選択状態にする
      const areAllElementsChecked = this.narrowedRoutesList.length > 0 && this.narrowedRoutesList.every(element => this.selectedRouteIdsTemp.includes(element.routeId));
      if (this.selectedRouteIdsTemp.length >= MANIFEST_MULTIPLE_SET_ROUTE_MAX || areAllElementsChecked) {
        if (!this.isAllChecked) this.isAllChecked = true;
      } else {
        if (this.isAllChecked) this.isAllChecked = false;
      }
    },
  },
  computed: {

    // 有害物質選択肢表示かチェック
    isDisplayHarmfulSelection: function() {
      return function (routeId) {
        const routes = this.formValue.routes;
        if (!routes || routes.length < 1) return false;

        const route = routes.find(r => r.routeId === routeId);
        const selectedJwnetItemId = route.wasteInfo.jwnetItemTypeId;
        const filteredJwnetItems = this.jwnetItemsOpt.filter(jwnetItem => jwnetItem.id === selectedJwnetItemId);
        if (filteredJwnetItems.length > 0) {
          return !!filteredJwnetItems[0].isHarmful;
        }

        return false;
      };
    },

    // 10件チェックされているときは、無効化する
    isDisabledCheckbox: function() {
      return function (routeId) {
        if (this.selectedRouteIdsTemp.length < 10) return false;
        const checked = this.selectedRouteIdsTemp.includes(routeId);
        if (!checked) return true;
        return false;
      };
    },
    routeInfo: function() {
      return function (routeId) {
        const route = this.routeDetailInfos.find(r => r.routeId === routeId);
        if (!route) return null;
        return route;
      };
    },
    routeBasicInfo: function() {
      return function (routeId) {
        const route = this.routeDetailInfos.find(r => r.routeId === routeId);
        if (!route) return null;
        return {
          routeId,
          routeCompanyName: {
            generateStore: route.generateStoreInfo.name,
            collectCompany: route.routeCollectCompanyInfo.filter(cc => cc.collectCompanyInfo.isActive === true).map((cc) => cc.collectCompanyInfo.name).join(","),
            disposalSite: route.routeDisposalCompanyInfo.destinationInfo.name,
          },
          jwnetItemTypeName: route.jwnetItemTypeInfo.name,
        };
      };
    },
    isDisabledRouteModal: function() {
      if (this.selectedRouteIdsTemp.length === 0) return true;
      return false;
    },
    showAddBtn: function() {
      return function (list, type) {
        if (!list) return true;

        let maxLength = 0;
        switch (type) {
          case 'contact':
            maxLength = 3;
            break;
          case 'harmful':
            maxLength = 6;
            break;
          case 'remarks':
            maxLength = 5;
            break;
          default:
            maxLength = 3;
            break;
        };

        const ListLength = list.length;
        if (ListLength >= maxLength) return  false;
        return true;
      };
    },
  },

  watch: {
    selectedGeneratorCollect: function(newVal) {
      this.routeModalSearchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedGeneratorDisposal: function(newVal) {
      this.selectedDisposalSite = '';
      this.routeModalSearchParam.disposalCompanyId = newVal ? newVal.id : ''
      if (this.routeModalSearchParam.disposalCompanyId) {
        this.disposalSiteList = this.disposalSiteOptions.filter(
            (ds) => ds.disposalCompanyId == newVal.id
        )
        this.selectDisposalSiteList = this.disposalSiteList;
      } else {
        this.disposalSiteList = this.disposalSiteOptions
        this.selectDisposalSiteList = this.disposalSiteList
      }
    },
    selectedDisposalSite: function(newVal) {
      this.routeModalSearchParam.disposalSiteId = newVal ? newVal.id : ''
    },
    'formValue.collectionDate'(newVal) {
      console.log(newVal)
      this.getRoutePriorconsultationAlert()
    },
    selectedGenerateStore: function(newVal) {
      this.formValue.generateStoreId = newVal ? newVal.id : null
      this.generateStoreChangeHandler()
    },
    isDisabledRouteModal(newVal) {
      if (newVal) this.errMsgsRouteModal = ['ルートを1つ以上選択してください'];
      else this.errMsgsRouteModal = [];
    },
    selectedAddress() {
      const routeId = this.routeIdInsertAddress;
      const route = this.formValue.routes.find(route => route.routeId === routeId);

      if (!route) return;

      const existTmpPref = this.selectedPrefectureTemp.find(pref => pref.routeId === routeId);
      if (!existTmpPref) {
        this.selectedPrefectureTemp.push({
          routeId,
          pref: this.selectedAddress.address1,
        });
      } else {
        this.selectedPrefectureTemp = this.selectedPrefectureTemp.map(pref => {
            if (pref.routeId !== routeId) return pref;
            return {
              ...pref,
              pref: this.selectedAddress.address1,
            };
          });
      }

      route.anyGenerateStoreInfo.anyGenerateStoreAddress.address = this.selectedAddress.address2;
      route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName = this.selectedAddress.address3;
    },
    selectedRouteIdsTemp(newVal) {
      // 10件 or 仮のルート一覧結果が全てチェックしたら、全選択状態にする
      const areAllElementsChecked = this.narrowedRoutesList.length > 0 && this.narrowedRoutesList.every(element => newVal.includes(element.routeId));

      if (newVal.length >= MANIFEST_MULTIPLE_SET_ROUTE_MAX || areAllElementsChecked) {
        if (!this.isAllChecked) this.isAllChecked = true;
      } else {
        if (this.isAllChecked) this.isAllChecked = false;
      }
    },
    // モーダルを開いたときに、前回の選択を復元する
    isRouteSelectModalShow(newVal) {
      if (newVal) this.selectedRouteIdsTemp = this.preSelectedRouteIds;
    },
    // 検索条件に産業廃棄物を動的に含める
    'routeModalSearchParam.industrialWasteJwnetTypeIdList'(newVal) {
      if (newVal.length === 0) {
        this.routeModalSearchParam.wasteTypeIdList = this.routeModalSearchParam.wasteTypeIdList.filter(wasteTypeId => {
            return wasteTypeId !== DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE;
          });

        return;
      }

      const existIndustrialWast = this.routeModalSearchParam.wasteTypeIdList.includes(DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE)
      if (newVal.length > 0 && !existIndustrialWast) {
        this.routeModalSearchParam.wasteTypeIdList.push(DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE)
      }
    },
  },

  mounted() {
    if (this.$route.query.date) {
      this.formValue.collectionDate = moment(this.$route.query.date).format("YYYY-MM-DD");
    }

    this.getManifestNoGetWayApi().then(() => {
      this.formValue.isUseAcquiredManifestNo = this.isUseAcquiredManifestNo;
    })

    // 初期表示に必要なAPIを呼ぶ
    this.getGenerateStoreOptApi().then(() => {
      // 事業場が1件のみの場合、初期値セット
      if (this.generateStoreOpt.length === 1) {
        const generateStoreId = this.generateStoreOpt[0].id
        this.formValue.generateStoreId = generateStoreId;
        this.validate.generateStoreId.isValid = true;
        this.getGenerateMemberGetWayApi(generateStoreId).then(() => {
          this.getMemberOptApi(generateStoreId).then(() => {
            // 担当者の初期値セット
            this.formValue.manifestInfo.registMemberId = this.$store.getters["userInfo/get"].id;
            if (this.generateMemberMetaInfo != null) {
              if (this.generateMemberMetaInfo.generateMemberGetWayMetaKey == this.generateMemberWayType.ID.metaKey) {
                this.isInputForm = true;
                this.formValue.manifestInfo.generateMember.id = Number(this.generateMemberMetaInfo.value);
                this.formValue.manifestInfo.generateMember.name = this.memberOpt.filter(m => m.id == this.formValue.manifestInfo.generateMember.id)[0].name;
              } else if (this.generateMemberMetaInfo.generateMemberGetWayMetaKey == this.generateMemberWayType.TEXT.metaKey) {
                this.isInputForm = false;
                this.formValue.manifestInfo.generateMember.id = "";
                this.formValue.manifestInfo.generateMember.name = this.generateMemberMetaInfo.value;
              }
            } else {
              this.isInputForm = true;
              this.formValue.manifestInfo.generateMember.id = this.$store.getters["userInfo/get"].id;
              this.formValue.manifestInfo.generateMember.name = this.memberOpt.filter(m => m.id == this.formValue.manifestInfo.generateMember.id)[0].name;
            }
          });
        });
        this.getEntrustJwnetItemsApi(this.generateStoreOpt[0].id);
      }
    });
    this.getRecurryingApi();
    this.getWasteUnitApi();
    this.getharmfulApi();
    this.getPrefecturesApi();
    this.getGeneratorCollectApi();
    this.getGeneratorDisposalApi();
    this.getDisposalSiteApi();
  },
};
</script>
