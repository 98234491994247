export default {
  data() {
    return {
      selectedRouteIds: [], //OKボタンを押下した後、選択したルートIDリスト
      // routeInfo  from callApi.js
      routeCompanyName: {},
      routeTempVal: "",
      collectCompanyId: "",
      disposalCompanyId: "",
      routeOptions: [],
      wdsFilePath: "",
      jwnetItemTypeName: "",

      // 一時的に使用用
      // 絞り込み後のルート（画面遷移時はAPI返り値全件を代入）
      narrowedRoutesList: [],
    };
  },
  methods: {
    // 選択された廃棄物種類をdataの値にセット
    setSelectedJwnetItem() {
      this.selectedJwnetItem = this.jwnetItemsOpt.filter(
        (ji) => ji.id === this.formValue.wasteInfo.jwnetItemTypeId
      )[0];
      if (!this.selectedJwnetItem.isHarmful) {
        this.formValue.harmfulInfo = [{ harmfulTypeId: null }];
      }
    },

    /**
     * ルートの選択後に各会社情報を一時データに代入
     */
    getRouteCompanyName() {
      this.routesInfo.map((routeInfo) => {
        if (routeInfo.routeId == this.formValue.manifestInfo.routeId) {
          this.routeCompanyName.generateStore = routeInfo.generateStore.name;
          this.jwnetItemTypeName = routeInfo.jwnetItemType.name;
          this.routeCompanyName.disposalCompany = routeInfo.disposalCompany.name;
          routeInfo.collectCompanys.map((collectCompany) => {
            if (collectCompany.id == this.collectCompanyId) {
              this.routeCompanyName.collectCompany = collectCompany.name;
            }
          });
        }
      });
    },

    // ルート情報APIで取得した値を適用
    setRoutesInfoValToFormVal(addRouteIds) {
      const f = this.formValue;
      const v = this.validate;
      const routes = this.routeDetailInfos;

      const routeIds = routes.map(_r => _r.routeId);
      // ルート荷姿選択肢取得APIを呼び出す
      this.getShapesApi(routeIds);
      // ルート処分方法選択肢取得APIを呼び出す
      this.getDisposalWayTypesApi(routeIds)
      // CBA品目IDを元にCBA品目別廃棄物名称選択肢取得APIを呼び出す
      this.getWasteNameCBAItemApi(routes)

      const addRoutes = routes
      .filter(route => addRouteIds.includes(route.routeId))
      .map(route => {
        const wasteUnitTypeIdObj = !route.plansWasteUnitType ? {} : { wasteUnitTypeId: route.plansWasteUnitType.id };
        const quantityConfirmBusinessTypeIdObj = !route.quantityConfirmBusinessType ? {} : { quantityConfirmBusinessTypeId: route.quantityConfirmBusinessType.id };
        const shapeTypeIdObj = !route.shapeType ? {} : { shapeTypeId: route.shapeType.id };
        const disposalWayTypeIdObj = !route.disposalWayType ? {} : { disposalWayTypeId: route.disposalWayType.id };


        const labelInfo = !route.labelInfo ? {} : {
          labelInfo: {
            labels: route.labelInfo.map(labelInfo => {
              return {
                id: labelInfo.labelId,
                header: labelInfo.labelHeader,
                labelValue: labelInfo.labelDefaultValue,
                labelUnit: labelInfo.labelUnit ? labelInfo.labelUnit : "",
              };
            })
          }
        };

        // バリデーション準備
        v.routes[route.routeId] = {
          detailWasteName : { isValid: true, errMsg: "" },
          wasteQuantity: { isValid: true, errMsg: "" },
          wasteUnitTypeId: { isValid: true, errMsg: "" },
          shapeQuantity: { isValid: true, errMsg: "" },
          anyGenerateStoreName: { isValid: true, errMsg: "" },
          zipCode: { isValid: true, errMsg: "" },
          prefecture: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          contactNo: !route.contactNoInfo?.length ? [] : route.contactNoInfo.map(() => { return { isValid: true, errMsg: "" }}),
          remarks: !route.remarksInfo?.length ? [] : route.remarksInfo.map(() => { return { isValid: true, errMsg: "" }}),
          buildingName: { isValid: true, errMsg: "" },
        };

        return {
          routeId: route.routeId,
          wasteInfo: {
            jwnetItemTypeId: route.jwnetItemTypeInfo.id, // 廃棄物種類
            wasteNameId: route.wasteNameInfo.id, // 廃棄物名称Id
            detailWasteName: route.detailWasteName,
            wasteQuantity: route.plansQuantity, // 廃棄物数量
            ...wasteUnitTypeIdObj, // 廃棄物単価
            ...quantityConfirmBusinessTypeIdObj, // 数量確定者
            ...shapeTypeIdObj, // 荷姿
            shapeQuantity: route.shapeQuantity, // 荷姿数量
            ...disposalWayTypeIdObj,  // 処分方法
          },
          // 有害物質
          harmfulInfo: !route.harmfulInfo.length ? [] : route.harmfulInfo.map(elem => ({ harmfulTypeId: elem.id })) , // 有害物質リスト
          // 連絡番号
          contactNoInfo: {
            contactNoList: !route.contactNoInfo?.length ? [] : route.contactNoInfo,
          },
          // 備考
          remarksInfo: {
            remarksList: !route.remarksInfo?.length ? [] : route.remarksInfo,
          },
          anyGenerateStoreInfo: {
            anyGenerateStoreName: "",
            anyGenerateStoreAddress: {
              zipCode: "",
              prefecturesId: "",
              address: "",
              buildingName: "",
            },
          },
          // ラベル・ノート
          ...labelInfo,
          conversionFactorKg: route.conversionFactorKg,
          conversionFactorM3: route.conversionFactorM3,
          conversionFactorLiter: route.conversionFactorLiter,
          conversionFactorUnitNumber: route.conversionFactorUnitNumber,
        };
      });

      const routesListOrder = this.narrowedRoutesList.map(list => list.routeId);
      const sortedAddRoutes = addRoutes.sort((routeIdA, routeIdB) => {
        return routesListOrder.indexOf(routeIdA) - routesListOrder.indexOf(routeIdB);
      });

      f.routes = [...f.routes, ...sortedAddRoutes];
    },

    // ルート選択時
    onChangeRouteHandler(selectedRouteIdsTemp) {
      if (selectedRouteIdsTemp.length > 0) {
        // 削除されたやつは除外する
        const removeIds = this.preSelectedRouteIds.filter(preId => !selectedRouteIdsTemp.includes(preId));

        this.formValue.routes = this.formValue.routes.filter(route => !removeIds.includes(route.routeId));
        removeIds.map(removeId => delete this.validate.routes[removeId]);
        const exitedSelectedRoutes = this.selectedRouteIds.filter(selectedRoute => !removeIds.includes(selectedRoute.routeId));
        this.routeDetailInfos = this.routeDetailInfos.filter(route => !removeIds.includes(route.routeId));
        this.selectedPrefectureTemp = this.selectedPrefectureTemp.filter(route => !removeIds.includes(route.routeId));

        // 追加されたものだけデータ取得する
        const routesListOrder = this.narrowedRoutesList.map(list => list.routeId);
        const addRouteIds = selectedRouteIdsTemp
          .filter(routeId => !this.preSelectedRouteIds.includes(routeId))
          .sort((routeIdA, routeIdB) => {
            return routesListOrder.indexOf(routeIdA) - routesListOrder.indexOf(routeIdB);
          });

        const addSelectedRoutes = addRouteIds.map(routeId => ({ routeId }));

        this.selectedRouteIds = [...exitedSelectedRoutes, ...addSelectedRoutes];

        this.preSelectedRouteIds = this.selectedRouteIds.map(route => route.routeId);

        // NOTE: エラー関係 初期化しておく
        this.newRouteDetailInfos = [];
        this.errMsgsRoute = [];
        this.errMsgsRouteCount = [];

        this.getRoutesInfoApi(addRouteIds).then(() => {
          this.setRoutesInfoValToFormVal(addRouteIds);
          this.getConfirmMemberApi(selectedRouteIdsTemp);
        });
        this.setSelectedPrefectureTemp(addRouteIds);
        this.getRoutePriorconsultationAlert();
      }
    },
  },
};
