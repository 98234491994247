export default {
  data() {
    return {
      formValue: {
        generateStoreId: "",
        collectionDate: "",
        collectionTime: "",
        isUseAcquiredManifestNo: null,
        manifestInfo: {
          manifestStatusId: "",
          registMemberId: "",
          generateMember: {
            id: null,
            name: null,
          },
          routeIds: [], // postDataでは削除
        },
        routes: [
          // {
          //   routeId: "",
          //   wasteInfo: {
          //     jwnetItemTypeId: "",
          //     wasteName: "",
          //     wasteQuantity: "",
          //     wasteUnitTypeId: "",
          //     quantityConfirmBusinessTypeId: "",
          //     shapeTypeId: "",
          //     shapeQuantity: "",
          //     disposalWayTypeId: "",
          //   },
          //   harmfulInfo: [
          //     {
          //       harmfulTypeId: 0
          //     }
          //   ],
          //   remarksInfo: {
          //     remarksList: [
          //       {
          //         remarks: "",
          //       },
          //     ],
          //   },
          //   contactNoInfo: {
          //     contactNoList: [
          //       {
          //         contactNo: "",
          //       }
          //     ],
          //   },
          // }
          // labelInfo: {
          //   labels: [
          //     {
          //       labelId: "",
          //       labelValue: "",
          //     }
          //   ],
          // },
          // anyGenerateStoreInfo: {
          //   anyGenerateStoreName: "",
          //   anyGenerateStoreAddress: {
          //      zipCode: "",
          //      prefecturesId: "",
          //      address: "",
          //      buildingName: ""
          //   }
          // },
          // conversionFactorKg: null,
          // conversionFactorM3: null,
          // conversionFactorLiter: null,
          // conversionFactorUnitNumber: null,
        ],
      },
    };
  },
  methods: {},
};
