export default {
  methods: {
    // テキストエリア追加
    addTextareaHandler(routeId) {
      const targetRoute = this.findTargetRoute(routeId);
      
      if (!targetRoute) {
        console.log('ルート情報がありません。システム管理者にお問い合わせください。');
        return;
      }

      if (targetRoute.remarksInfo.remarksList.length >= 5) return;
      targetRoute.remarksInfo.remarksList.push({
        remarks: "",
      });
      this.validate.routes[routeId].remarks = [
        ...this.validate.routes[routeId].remarks,
        { isValid: true, errMsg: "" }
      ];
    },
    // テキストエリア削除
    removeTextareaHandler(ta, routeId) {
      const targetRoute = this.findTargetRoute(routeId);
      
      if (!targetRoute) {
        console.log('ルート情報がありません。システム管理者にお問い合わせください。');
        return;
      }

      targetRoute.remarksInfo.remarksList = targetRoute.remarksInfo.remarksList.filter((t) => t != ta);
      this.validate.routes[routeId].remarks.pop();
    },
    // ターゲットのルート情報を探す
    findTargetRoute(routeId){
      return this.formValue.routes.find(route => route.routeId === routeId );
    },
  },
};
