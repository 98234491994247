export default {
  methods: {
    // 有害物質名追加
    addHarmfulTypeHandler(routeId) {
      const targetRoute = this.findTargetRoute(routeId);
      
      if (!targetRoute) {
        console.log('ルート情報がありません。システム管理者にお問い合わせください。');
        return;
      }

      targetRoute.harmfulInfo.push({
        harmfulTypeId: null,
      });
    },
    // 有害物質名削除
    removeHarmfulTypeHandler(harmfulType, routeId) {
      const targetRoute = this.findTargetRoute(routeId);
      
      if (!targetRoute) {
        console.log('ルート情報がありません。システム管理者にお問い合わせください。');
        return;
      }

      targetRoute.harmfulInfo = targetRoute.harmfulInfo.filter(
        (ht) => ht != harmfulType
      );
    },
    // ターゲットのルート情報を探す
    findTargetRoute(routeId){
      return this.formValue.routes.find(route => route.routeId === routeId );
    },
  },
};
