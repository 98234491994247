export default {
  methods: {
    // 連絡番号追加
    addContactNoHandler(routeId) {
      const targetRoute = this.findTargetRoute(routeId);
      
      if (!targetRoute) {
        console.log('ルート情報がありません。システム管理者にお問い合わせください。');
        return;
      }

      if(targetRoute.contactNoInfo.contactNoList.length >= 3) return
      targetRoute.contactNoInfo.contactNoList.push({
        contactNo: "",
      });

      //バリデーションも追加
      this.validate.routes[routeId].contactNo = [
        ...this.validate.routes[routeId].contactNo,
        { isValid: true, errMsg: "" }
      ];
    },
    // 連絡番号削除
    removeContactNoHandler(contactNo, index, routeId) {
      const targetRoute = this.findTargetRoute(routeId);
      
      if (!targetRoute) {
        console.log('ルート情報がありません。システム管理者にお問い合わせください。');
        return;
      }
      
      targetRoute.contactNoInfo.contactNoList = targetRoute.contactNoInfo.contactNoList.filter(
        (cn) => cn != contactNo
      );
        
      //バリデーションも削除
      this.validate.routes[routeId].contactNo = this.validate.routes[routeId].contactNo.filter((_cv, i) => i != index)
    },
    // ターゲットのルート情報を探す
    findTargetRoute(routeId){
      return this.formValue.routes.find(route => route.routeId === routeId );
    },
  },
};
